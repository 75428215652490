import styled from "@emotion/styled";

const LoadingWrapper = styled.div`
  display: flex;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #7f7f7f;
  z-index: 100;
  opacity: 0.6;

  section {
    position: absolute;
    top: 50%;
    /* left: 50%; */
    margin-top: -20px;
    margin-right: -25px;
    width: 100px;
    height: 80px;
    text-align: center;
    font-size: 10px;
  }
`;

const S = {
  LoadingWrapper,
};

export default S;
