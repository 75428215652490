import { useContext } from "react";
import { LoadingContext } from "src/contexts/LoadingContext/Loading.provider";

export default function useLoading() {
  const { setLoading, loading } = useContext(LoadingContext);

  return {
    loading,
    showLoading: (show: boolean) => setLoading(show),
  };
}
