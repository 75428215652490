import jwt_decode from "jwt-decode";
import { UserProfile } from "../../auth.types";

interface TokenDecoded {
  upn: string;
  name: string;
}

export const getUserProfile = (accessToken: string | null): UserProfile | null => {
  if (!accessToken) {
    return null;
  }

  // Decode Token
  const { name, upn: email }: TokenDecoded = jwt_decode(accessToken);
  console.log(accessToken);

  // Format internal Token
  return {
    email,
    name,
    token: accessToken,
  };
};
