import axios from "axios";
import { api } from "src/environment";
import { AuthorizeUserAccessRequest, AuthorizeUserAccessService } from "../auth.types";
import { AuthorizeUserAccessAdaptor } from "./authorizeUserAccess.adaptor";

export const authorizeUserAccessService: AuthorizeUserAccessService = async ({
  token,
}) => {
  const path = `${api}/login/authenticate`;

  const headers = {
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json",
  };

  try {
    const { data } = await axios.get<AuthorizeUserAccessRequest>(path, {
      headers,
    });

    // user not found or have no access
    if (!data) {
      return null;
    }

    const authorizeUserAccessAdaptor = new AuthorizeUserAccessAdaptor(data);

    return authorizeUserAccessAdaptor;
  } catch (err) {
    console.warn(err);
    return null;
  }
};
