import { ReactNode, createContext, useState } from "react";
import { LoadingStateDefault } from "./Loading.constants";
import { LoadingState } from "./Loading.types";

export const LoadingContext = createContext<LoadingState>(LoadingStateDefault);

export const LoadingProvider = ({ children }: { children: ReactNode }) => {
  const [loading, setLoading] = useState<boolean>(false);

  return (
    <LoadingContext.Provider
      value={{
        loading,
        setLoading,
      }}
    >
      {children}
    </LoadingContext.Provider>
  );
};
