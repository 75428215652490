const LOGIN_HINT = "login_hint";
const FORCE_LOGIN = "force_login";

export const getLoginHint = (): string | null => {
  const searchParams = new URLSearchParams(document.location.search);
  return searchParams.get(LOGIN_HINT);
};

export const getForceLogin = (): boolean => {
  const searchParams = new URLSearchParams(document.location.search);
  const forceLogin = searchParams.get(FORCE_LOGIN);
  return forceLogin === "true";
};
