import { ReactNode, createContext, useState } from "react";
import { serviceRequestErrorStateDefault } from "./ServiceRequestError.constants";
import { ApiError, ServiceRequestErrorState } from "./ServiceRequestError.types";

export const ServiceRequestErrorContext = createContext<ServiceRequestErrorState>(
  serviceRequestErrorStateDefault,
);

export const ServiceRequestErrorProvider = ({ children }: { children: ReactNode }) => {
  const [errors, setErrors] = useState<ApiError[]>([]);

  return (
    <ServiceRequestErrorContext.Provider
      value={{
        errors,
        hasErrors: errors.length > 0 ?? false,
        setErrors,
      }}
    >
      {children}
    </ServiceRequestErrorContext.Provider>
  );
};
