import { Env } from "../env.type";

const clientId = "c006555d-2ac1-4819-994c-96691b14a530";

const env: Env = {
  host: "http://localhost:30064",
  nameAplication: "Gestão Automática de Bolsas - GAB",
  initialsApplication: "GAB",
  env: "stg",
  brand: "yduqs",
  api: "",
  apm: "",
  auth: {
    clientId: clientId,
    authority: `https://login.microsoftonline.com/fbd59aac-4f60-4afc-ba05-08dfa67b78fb/`,
    //scopes: ['openid', 'offline_access', 'profile', 'User.Read'],
    scopes: [`${clientId}/.default`],
  },
};

export default env;
