import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import { getAuthClientConfig } from "./auth.config";
import { AuthProviderProps } from "./auth.types";
import { MsalAuthProvider } from "./contexts/MsalAuth.provider";
import { getLoginHint } from "./utils/searchParams/searchParams";
import { setPreferredAccount } from "./utils/session/session";

export const AuthProvider = ({ onLoading, children, authConfig }: AuthProviderProps) => {
  const { clientId, authority, scopes } = authConfig;
  const loginHint = getLoginHint();

  if (loginHint) {
    setPreferredAccount(loginHint);
    window.location.href = `${window.location.protocol}//${window.location.host}${window.location.pathname}`;
  }

  const clientConfig = getAuthClientConfig({
    clientId,
    authority,
  });

  const msalInstance = new PublicClientApplication(clientConfig);

  return (
    <MsalProvider instance={msalInstance}>
      <MsalAuthProvider config={{ scopes }} onLoading={onLoading}>
        {children}
      </MsalAuthProvider>
    </MsalProvider>
  );
};
