import styled from "@emotion/styled";

const Template = styled.section`
  display: flex;
  height: 100%;
  width: 100%;
  align-items: center;
  justify-content: center;
`;

export const S = { Template };
