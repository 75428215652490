import { init } from "@elastic/apm-rum";
import { ApmProps } from "./apm.types";

export const initializeApm = ({ api, apm, env, brand }: ApmProps) => {
  const readEnvironment = () => {
    switch (env) {
      case "dev":
        return "development";
      case "stg":
        return "homolog";
      case "prd":
        return "production";
      case "local":
      default:
        return null;
    }
  };

  const currentEnv = readEnvironment();

  if (currentEnv) {
    init({
      serviceName: `PROJECT_NAME`,
      serverUrl: apm,
      serviceVersion: brand,
      environment: currentEnv,
      distributedTracing: true,
      distributedTracingOrigins: [api],
    });
  }
};
