import { BrowserCacheLocation, Configuration, LogLevel } from "@azure/msal-browser";
import { ClientConfig } from "./auth.types";

const REDIRECT_URI = `${window.location.protocol}//${window.location.host}`;

export const loggerCallback = (
  level: LogLevel,
  message: string,
  containsPii: boolean,
) => {
  if (containsPii) {
    return;
  }

  switch (level) {
    case LogLevel.Error:
      //console.error(message);

      return;

    case LogLevel.Info:
      //console.info(message);

      return;

    case LogLevel.Verbose:
      //console.debug(message);

      return;

    case LogLevel.Warning:
      //console.warn(message);

      return;
  }
};

export const getAuthClientConfig = (clientConfig: ClientConfig): Configuration => {
  const { clientId, authority } = clientConfig;

  return {
    auth: {
      clientId,
      authority,
      redirectUri: REDIRECT_URI,
      postLogoutRedirectUri: REDIRECT_URI,
    },
    cache: {
      cacheLocation: BrowserCacheLocation.LocalStorage,
      storeAuthStateInCookie: false,
    },
    system: {
      loggerOptions: {
        loggerCallback,
      },
    },
  };
};
