const ACCESS_TOKEN = "access_token";
const PREFERRED_ACCOUNT = "preferred_account";

export const removeSession = () => {
  localStorage.removeItem(ACCESS_TOKEN);
  localStorage.removeItem(PREFERRED_ACCOUNT);
};

export const setSession = (token: string) => {
  localStorage.setItem(ACCESS_TOKEN, token);
};

export const getPreferredAccount = () => {
  return localStorage.getItem(PREFERRED_ACCOUNT);
};

export const setPreferredAccount = (email: string) => {
  localStorage.setItem(PREFERRED_ACCOUNT, email);
};
