import { LftLoading } from "@lift/design-system-react-web/dist/components";
import useLoading from "src/hooks/useLoading.hook";
import S from "./Loading.style";

const Loading = () => {
  const { loading } = useLoading();

  return (
    <>
      {loading && (
        <S.LoadingWrapper>
          <LftLoading />
        </S.LoadingWrapper>
      )}
    </>
  );
};

export default Loading;
