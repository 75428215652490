import {
  AccountInfo,
  InteractionRequiredAuthError,
  InteractionStatus,
  RedirectRequest,
} from "@azure/msal-browser";
import { useMsal } from "@azure/msal-react";
import { createContext, useCallback, useEffect, useMemo, useState } from "react";
import { Context, MsalAuthProviderProps, UserProfile } from "../auth.types";
import { authorizeUserAccessService } from "../services/authorizeUserAccess.service";
import { getPreferredAccount, removeSession, setSession } from "../utils/session/session";
import { getUserProfile } from "../utils/userProfile/getUserProfile";

export const AuthContext = createContext<Context>(null);

export const MsalAuthProvider = ({
  children,
  onLoading,
  config,
}: MsalAuthProviderProps) => {
  const [accessToken, setAccessToken] = useState<string>("");
  const [userProfile, setUserProfile] = useState<UserProfile | null>(null);
  const [authorized, setAuthorized] = useState<boolean | null>(null);

  /**
   * Utilização do MSAL
   * @returns {
   *   instance: 'instância do client do MSAL',
   *   accounts: 'array de contas de usuários autenticadas',
   *   inProgress: 'estado da intereção (ver: node_modules/@azure/msal-browser/dist/utils/BrowserConstants.d.ts)'
   * }
   */
  const { instance, accounts, inProgress } = useMsal();

  const scopes = useMemo(() => config.scopes, [config.scopes]);

  const signIn = useCallback(() => {
    const preferredAccount = getPreferredAccount();
    /* console.warn(`preferredAccount: ${preferredAccount}`); */
    let options: RedirectRequest = {
      scopes,
    };

    if (preferredAccount) {
      options = {
        ...options,
        loginHint: preferredAccount,
      };
    }

    /* console.log("signIn", preferredAccount); */

    instance.loginRedirect(options).catch(() => {
      removeSession();
    });
  }, [instance, scopes]);

  const signOut = useCallback(() => {
    removeSession();
    instance.logoutRedirect();
  }, [instance]);

  const acquireTokenToActiveAccount = useCallback(
    async (activeAccount: AccountInfo): Promise<void> => {
      const requestPayload = {
        scopes,
        account: activeAccount,
        forceRefresh: true,
      };

      if (activeAccount && inProgress === InteractionStatus.None) {
        return instance
          .acquireTokenSilent(requestPayload)
          .then(async (result): Promise<void> => {
            const accessToken =
              typeof result.accessToken !== "undefined" && result.accessToken.length !== 0
                ? result.accessToken
                : result.idToken;

            setSession(accessToken);
            setAccessToken(accessToken);
          })
          .catch(async (e) => {
            if (e instanceof InteractionRequiredAuthError) {
              await instance.acquireTokenRedirect(requestPayload);
            }
          });
      }
    },
    [inProgress, instance, scopes],
  );

  // useEffect login acquireTokenToActiveAccount
  useEffect(() => {
    if (inProgress !== InteractionStatus.None) {
      return;
    }

    const preferredAccount = getPreferredAccount();

    /* console.log("preferredAccount", preferredAccount);

    console.log("accounts", accounts); */

    if (preferredAccount) {
      const accountInfo = accounts.find(
        (acc) =>
          acc.username === preferredAccount ||
          acc?.idTokenClaims?.email === preferredAccount,
      );

      /* console.log("accountInfo", accountInfo); */

      if (accountInfo) {
        acquireTokenToActiveAccount(accountInfo);
        return;
      }

      signIn();
      return;
    }

    // Não está logado
    if (accounts.length === 0) {
      return;
    }

    acquireTokenToActiveAccount(accounts[0]);
  }, [accounts, signOut, inProgress, signIn, acquireTokenToActiveAccount]);

  // Faz o parse do token e set em um state
  useEffect(() => {
    const userProfile = getUserProfile(accessToken);
    setUserProfile(userProfile);
  }, [accessToken]);

  //Chama api para verificar se usuário tem autorização de acesso ao sistema
  useEffect(() => {
    if (!accessToken || userProfile) {
      return;
    }
    const autheticateUser = async () => {
      const userProfile = await authorizeUserAccessService({
        token: accessToken,
      });
      const response = await userProfile;

      if (!response) {
        setAuthorized(false);
        return false;
      }
      setAuthorized(true);
      setUserProfile((prevUserProfile) => ({
        ...prevUserProfile,
        id: response.id,
        userType: response.userType,
        poleFlag: response.poleFlag,
        hub: response.hub,
        inactive: response.inactive,
        emailAccessResponsable: response.emailAccessResponsable,
      }));
    };

    autheticateUser();
  }, [accessToken, userProfile]);

  const result = useMemo(
    () => ({
      pending: inProgress !== InteractionStatus.None,
      authenticated: accounts.length > 0,
      authorized: authorized,
      signIn,
      signOut,
      accessToken,
      userProfile,
    }),
    [accounts.length, inProgress, signIn, signOut, accessToken, userProfile, authorized],
  );

  if (inProgress === InteractionStatus.Logout || inProgress !== InteractionStatus.None) {
    return onLoading;
  }

  sessionStorage.removeItem("login_hint");
  return <AuthContext.Provider value={result}>{children}</AuthContext.Provider>;
};
