import { LftLoading } from "@lift/design-system-react-web/dist/components";
import { lazy, useMemo } from "react";
import { FullLayout } from "src/components/FullLayout/FullLayout.component";
import { useAuth } from "src/libs/auth";
import S from "./Navigation.style";

const PrivateRouter = lazy(() => import("./components/Private.component"));
const PubliceRouter = lazy(() => import("./components/Public.component"));

export const Navigation = () => {
  const { authenticated, authorized } = useAuth();

  const app = useMemo(
    () =>
      !authenticated || (authenticated && authorized === false) ? (
        <PubliceRouter />
      ) : authenticated && authorized ? (
        <PrivateRouter />
      ) : (
        <FullLayout>
          <LftLoading />
        </FullLayout>
      ),
    [authenticated, authorized],
  );

  return <S.Template>{app}</S.Template>;
};
