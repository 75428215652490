import { css } from "@emotion/react";
import emotionNormalize from "emotion-normalize";

export const globalStyles = css`
  ${emotionNormalize}
  html,
  body {
    background-color: rgba(32, 65, 99, 1);
    display: flex;
    flex: 1;
    padding: 0;
    margin: 0;
    min-height: 100%;

    #root {
      width: 100%;
    }
  }

  * {
    margin: 0px;
    padding: 0px;
    outline-color: rgb(194, 212, 255);
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
  }

  ul[aria-label="listbox"] {
    height: auto;
    max-height: 40vh;
    overflow-y: auto;
    overflow-x: hidden;
  }
`;
