import { useContext } from "react";
import { AuthHook } from "../auth.types";
import { AuthContext } from "../contexts/MsalAuth.provider";

export const useAuth: AuthHook = () => {
  const context = useContext(AuthContext);

  if (context) {
    return context;
  }

  throw new Error(
    "O contexto de autenticação ainda não está pronto. Verifique o local da sua chamada",
  );
};
