import styled from "@emotion/styled";

const Template = styled.main`
  width: 100%;
  height: 100%;
  word-break: break-word;
`;

const S = {
  Template,
};

export default S;
