import { Env } from "../env.type";

const clientId = "d0cd4052-7b01-43e9-837a-a2ecf4bcf009";

const env: Env = {
  host: "https://gab-dev.yduqs.com.br",
  nameAplication: "Gestão Automática de Bolsas - GAB",
  initialsApplication: "GAB",
  env: "dev",
  brand: "yduqs",
  api: "https://api.gab-dev.yduqs.com.br",
  apm: "",
  auth: {
    clientId: clientId,
    authority: `https://login.microsoftonline.com/29e081cc-983f-4d52-b1d0-103595213bc6/`,
    //scopes: ['openid', 'offline_access', 'profile', 'User.Read'],
    scopes: [`${clientId}/.default`],
  },
};

export default env;
