import { AuthorizeUserAccessRequest, UserProfile } from "../auth.types";

export class AuthorizeUserAccessAdaptor implements UserProfile {
  public id: number;
  public email?: string;
  public name?: string;
  public userType?: string;
  public poleFlag?: string | undefined;
  public hub?: string;
  public inactive?: boolean | null;
  public emailAccessResponsable?: string | null;

  constructor(data: AuthorizeUserAccessRequest) {
    this.id = data.ID_USER;
    this.email = data.EMAIL;
    this.userType = data.COD_TIPO_USER;
    this.poleFlag = data.FLAG_POLOS;
    this.hub = data.COD_POLO;
    this.inactive = data.IND_INATIVO;
    this.emailAccessResponsable = data.EMAIL_RESP_ACESSO;
  }
  get userProfile(): UserProfile {
    return {
      id: this.id,
      email: this.email,
      userType: this.userType,
      poleFlag: this.poleFlag,
      hub: this.hub,
      inactive: this.inactive,
      emailAccessResponsable: this.emailAccessResponsable,
    };
  }
}
