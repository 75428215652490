import { Env } from "./env.type";

// Environment
import GabDev from "./gab/dev";
import GabLocal from "./gab/local";
import GabPrd from "./gab/prd";
import GabStg from "./gab/stg";

const contexts: Array<Env> = [GabDev, GabLocal, GabPrd, GabStg];

const context = (() => {
  const host = `${window.location.protocol}//${window.location.host}`;

  if (host.includes("www")) {
    window.location.href = host.replace("www.", "");
  }

  const context = contexts.find((ctx) => ctx.host === host);

  if (typeof context === "undefined") {
    throw new Error("Configuration not found, verify environment");
  }

  return context;
})();

export const { host, env, brand, api, auth, apm, nameAplication, initialsApplication } =
  context;
export default context;
